export const INDICATORS = [

    {
        name: 'Beleidsvisie',
        categories: [
            /* 1. */
            {
                name: <div><b>Onze school beschikt over een formele beleidsvisie omtrent pesten, cyberpesten en (zo mogelijk) ander grensoverschrijdend gedrag waarbij leerlingen betrokken zijn.</b></div>,
                subCategories: [
                    {
                        name: <div>De visietekst is <b>uitgeschreven en maakt deel uit van het schoolbeleid</b>.</div>,
                        weight: 1,
                        id: 1
                    },
                    {
                        name: <div>De tekst <b>bevat</b> een heldere <b>definitie van pesten én cyberpesten</b>. </div>,
                        weight: 1,
                        id: 2
                    }
                ]
            },

            /* 2. */
            {
                name: <div>Het uitgeschreven antipest- of pestpreventiebeleid neemt een volwaardige plaats in en <b>koppelt
                    zich aan andere aspecten van de schoolwerking</b> (vb. speelplaatswerking, schoolreglement/tucht- en
                    ordebeleid, participatiebeleid, nascholingsbeleid, gezonde school beleid, ...).</div>,
                subCategories: [
                    {
                        name: <div>Het beleid om pesten en cyberpesten te helpen voorkomen/aan te pakken <b>koppelt zich
                            aan de speelplaatswerking</b> (toezicht doen, hoe reageren op pestsituaties?), <b>het
                            gezondheidsbeleid van de school, de aandacht voor positief omgaan van leerlingen</b> (met
                            leerlingen en leerkrachten) binnen en buiten de klas, <b>het ICT-beleid en de lessen
                                IT/mediawijsheid</b>, ... .</div>,
                        weight: 2,
                        id: 3
                    },
                    {
                        name: <div>De <b>beleidsvisie is herkenbaar aanwezig in het schoolreglement</b> en/of in de
                            informatie die ouders (of andere opvoeders) ter beschikking wordt gesteld.</div>,
                        weight: 1,
                        id: 4
                    },
                    {
                        name: <div><b>Het antipest- of pestpreventiebeleid is onderdeel van het zorgbeleid</b> van de
                            school en koppelt zich aan de invulling die de school geeft aan het zorgcontinuüm
                            (basiszorg, verhoogde zorg en uitbreiding van zorg).</div>,
                        weight: 1,
                        id: 5
                    }
                ]
            },

            /* 3. */
            {
                name: <div>De school maakt werk van een integrale of school-brede aanpak.</div>,
                subCategories: [
                    {
                        name: <div><b>De school betrekt (informeert, consulteert, overlegt, activeert) alle leden van de
                            schoolgemeenschap bij het gevoerde beleid</b>, m.n. de directie, het opvoedend personeel, de
                            leerkrachten, de leerlingen, de ouders. Idem voor de personen die toezicht komen doen
                            tijdens het ochtend-, middag- of naschools toezicht. </div>,
                        weight: 1,
                        id: 6
                    },
                    {
                        name: <div>De school kan aantonen (video, foto’s, teksten, getuigenissen, …) dat haar beleid een<b> combinatie
                            vormt van preventieve, sensibiliserende en probleemoplossende elementen</b> die zich
                            gespreid aanbieden (vb. niet enkel tijdens de Vlaamse Week tegen Pesten).</div>,
                        weight: 2,
                        id: 7
                    }
                ]
            },
            /* 4 */
            {
                name: <div><b>In de beleidsvisie en in de praktijk trekt de school de kaart van een oplossings- en
                    herstelgerichte aanpak</b>.</div>,
                subCategories: [{name: null, weight: 1, id: 8}]
            },
        ]
    },


    {
        name: 'Participatie en gedragenheid',
        categories: [
            /* 5 */
            {
                name: <div><b>De directie stelt zich geëngageerd en betrokken op</b>: het is voor iedereen duidelijk dat
                    ze begaan is met het pestactieplan van de school. </div>,
                subCategories: [
                    {
                        name: <div><b>De directie maakt op meer dan een manier duidelijk dat dit beleidsonderdeel (het
                            antipestplan/pestpreventieplan) voor haar belangrijk is</b>. Ze spreekt haar eigen
                            engagement uit en rekent op de inzet van alle betrokkenen. </div>,
                        weight: 1,
                        id: 9
                    },
                    {
                        name: <div><b>De directie kijkt mee toe op de uitvoering van het schoolactieplan</b> om pesten
                            en cyberpesten te helpen terugdringen. Ze enthousiasmeert het team, evalueert de
                            ontwikkelingen en volgt alles dus met aandacht op. </div>,
                        weight: 1,
                        id: 10
                    }
                ]
            },

            /* 6 */
            {
                name: <div><b>De school beschikt over een werkgroep</b> met vaste leden die samen zorg dragen voor de
                    uitvoering van het antipestplan/pestpreventieplan. </div>,
                subCategories: [
                    {
                        name: <div><b>De werkgroep zoekt samenwerking met de andere leden van het schoolteam en de
                            schoolgemeenschap</b>. Ze streeft naar maximale betrokkenheid van collega’s, leerlingen en
                            ouders.</div>,
                        weight: 1,
                        id: 11
                    },
                    {
                        name: <div>De werkgroep heeft een <b>formeel mandaat</b>, kan zelf initiatief ontwikkelen en kan
                            rekenen op het gehoor en de steun van de directie.</div>,
                        weight: 1,
                        id: 12
                    },
                    {
                        name: <div><b>De werkgroep</b> toont jaar na jaar initiatief en <b>evalueert met regelmaat haar
                            eigen realisaties en werking</b>.</div>,
                        weight: 1,
                        id: 13
                    },
                ]
            },

            /* 7 */
            {
                name: <div>Het antipest- of pestpreventiebeleid is een zaak van de hele schoolgemeenschap: <b>de school
                    kiest voor maximale participatie van alle betrokkenen</b>.</div>,
                subCategories: [
                    {
                        name: <div>De school kiest/ koos er voor om zowel de collega’s, de leerlingen en – voor zover
                            haalbaar - ook de ouders te betrekken <b>bij het ontwerpen van het beleid</b>.</div>,
                        weight: 1,
                        id: 14
                    },
                    {
                        name: <div>De school kiest/koos er voor om de eigen collega’s en de leerlingen en - voor zover
                            haalbaar - ook de ouders te betrekken <b>bij het implementeren van het beleidsplan/de
                                diverse initiatieven</b> die genomen werden/worden.</div>,
                        weight: 1,
                        id: 15
                    },
                    {
                        name: <div>De school kiest er voor om alle collega’s en leerlingen en – voor zover haalbaar –
                            ook de ouders te betrekken <b>bij het detecteren/signaleren en/of vroegtijdig opsporen van
                                incidenten</b> van kwetsend en/of (cyber)pestend gedrag.</div>,
                        weight: 1,
                        id: 16
                    },
                    {
                        name: <div>De school kiest er voor om leerkrachten, leerlingen en ouders <b>actief te betrekken bij het
                            aanpakken van incidenten</b> van kwetsend en/of (cyber)pestend gedrag.</div>,
                        weight: 1,
                        id: 17
                    }
                ]
            },
            /* 8 */
            {
                name: <div><b>De school levert inspanningen om het team te bekwamen in het veilig en goed communiceren
                    en samenwerken met leerlingen en ouders</b>.</div>,
                subCategories: [{name: null, weight: 1, id: 18}]
            },
        ]
    },

    {
        name: 'Communicatie',
        categories: [
            /* 9 */
            {
                name: <div><b>De school toont (letterlijk en figuurlijk) dat ze begaan is met het welbevinden en veilig
                    gevoel van al haar leerlingen. Ze maakt duidelijk dat (cyber)pestgedrag niet gewenst is en dat ze er
                    op rekent dat iedereen dit gedrag afkeurt, er niet aan mee doet en incidenten komt melden.</b>
                </div>,
                subCategories: [
                    {
                        name: <div><b>Leerlingen, leerkrachten en ouders worden aanhoudend en op meerdere manieren
                            geïnformeerd en gesensibiliseerd</b> omtrent (cyber)pesten. (De betrokkenen kunnen dit op
                            basis van hun ervaringen bevestigen.)</div>,
                        weight: 2,
                        id: 19
                    },
                    {
                        name: <div>De school maakt <b>ook via haar online communicatie</b> (website, sociale media,
                            nieuwsbrieven, …) duidelijk hoe ze aankijkt tegen (cyber)pesten en ander grensoverschrijdend
                            gedrag.</div>,
                        weight: 1,
                        id: 20
                    },
                ]
            },

            /* 10 */
            {
                name: <div><b>De school communiceert duidelijk over haar specifiek zorg- en hulpaanbod voor wie te maken
                    krijgt met (cyber)pesten: pestproblemen kunnen door iedereen vlot (laagdrempelig) worden gemeld.</b>
                </div>,
                subCategories: [
                    {
                        name: <div><b>Leerkrachten en al wie op school met de leerlingen aan de slag gaat zijn goed
                            geïnformeerd over wat hen te doen staat (a) om pestproblemen te voorkomen; (b) wanneer zich
                            een pest- of cyberpestprobleem aandient (via melding of via observatie).
                            De communicatie hierover gebeurt met regelmaat en is makkelijk terug te vinden.</b></div>,
                        weight: 3,
                        id: 21
                    },
                    {
                        name: <div><b>Ook de leerlingen weten, dankzij grondige informatie en sensibilisatie, wat hen te
                            doen staat</b> in situaties waarin zij zelf of andere leerlingen te maken krijgen met
                            (mogelijk) pest- of cyberpestgedrag.</div>,
                        weight: 1,
                        id: 22
                    },
                    {
                        name: <div><b>Ook de ouders worden geïnformeerd (off- en online) over de zorgaanpak van de
                            school en over de verantwoordelijkheid die zij kunnen opnemen</b> wanneer zich een
                            pestprobleem stelt.</div>,
                        weight: 1,
                        id: 23
                    },
                    {
                        name: <div><b>De school informeert leerlingen en ouders ook over het hulpaanbod dat buiten de
                            school ter beschikking staat en waarbij zij eventueel ook terecht kunnen.</b></div>,
                        weight: 1,
                        id: 24
                    },
                ]
            },

            /* 11 */
            {
                name: <div>De school beschikt over <b>een digitaal meldpunt</b> dat iedereen (leerlingen, ouders,
                    buitenstaanders) toelaat om een (cyber)pestprobleem discreet en in alle veiligheid te melden.
                </div>,
                subCategories: [{name: null, weight: 1, id: 25}]
            }
        ]
    },


    {
        name: 'Samenwerking',
        categories: [
            /* 12 */
            {
                name: <div>De school zet (preventief) in op het creëren van een verbindende en warme schoolcultuur die
                    positieve contacten in de hand werkt, open communicatie bevordert en samenwerking vanzelfsprekend
                    maakt.</div>,
                subCategories: [
                    {
                        name: <div>Directie en schoolteam vinden het belangrijk om <b>elk schooljaar opnieuw</b> werk te
                            maken van initiatieven (vaste en nieuwe) die de <b>positieve omgang en samenwerking tussen
                                leerkrachten en leerlingen</b> in de hand werken.</div>,
                        weight: 1,
                        id: 26
                    },
                    {
                        name: <div>Directie en schoolteam zorgen er voor dat ouders zich op school welkom en thuis
                            voelen. <b>Men levert inspanningen om alle ouders goed te informeren, te beluisteren en bij
                                het schoolgebeuren te betrekken</b> (nog voor zich problemen voordoen).</div>,
                        weight: 1,
                        id: 27
                    },
                    {
                        name: <div><b>Het schoolteam zet preventief in op (a) het versterken van de sociale vaardigheden
                            van en bij de leerlingen</b> (i.f.v. hun off- en online omgang) <b>en (b) het creëren/in
                            stand houden van een positieve groepsdynamiek in de klassen/leergroepen.</b></div>,
                        weight: 2,
                        id: 28
                    },
                    {
                        name: <div><b>De school maakt extra werk van leerlingen die het lastiger hebben in de sociale
                            omgang</b> met leerlingen en/of leerkrachten: door in te zetten op <b>samenwerking</b> tussen
                            leerlingen, het leerkrachtenteam en de ouders wordt getracht een duurzaam verschil te
                            maken.</div>,
                        weight: 1,
                        id: 29
                    },
                ]
            },

            /* 13 */
            {
                name: <div><b>De school probeert via haar sensibiliserende initiatieven t.a.v. leerlingen, leerkrachten
                    en ouders duidelijk te maken dat pesten stoppen een gezamenlijke verantwoordelijkheid is</b>: als
                    ieder zijn/haar/hun stukje verantwoordelijkheid neemt, maakt pestgedrag geen kans.</div>,
                subCategories: [
                    {
                        name: <div><b>De school leert leerlingen (via lessen, activiteiten en oefenmomenten) hoe ze op
                            een geweldloze en respectvolle manier met wrijvingen en conflicten (o.a. ook
                            pesterijen/online pesten) kunnen omgaan</b> om er voor te zorgen dat niemand geschaad wordt.
                        </div>,
                        weight: 2,
                        id: 30
                    },
                    {
                        name: <div><b>De school beschikt over een duidelijk handelingsprotocol</b> (als houvast en niet als
                            keurslijf) dat leerkrachten op weg zet naar het gepast en gezamenlijk (door middel van
                            taakafbakening, procedures, werkafspraken) aanpakken van pest- en cyberpestproblemen.
                        </div>,
                        weight: 1,
                        id: 31
                    },
                    {
                        name: <div><b>Ouders vinden vlot terug wie ze moeten contacteren en hoe er met hen zal worden
                            samengewerkt wanneer zich een (cyber)pestprobleem voordoet.</b></div>,
                        weight: 1,
                        id: 32
                    },
                ]
            },

            /* 14 */
            {
                name: <div><b>De school zorgt er voor dat leerlingen goed weten bij wie ze kunnen/mogen aankloppen
                    wanneer zij zelf in de knoei geraken of als een ander in de knoei is geraakt en zij zich daarover
                    zorgen maken.</b></div>,
                subCategories: [{name: null, weight: 1, id: 33}]
            }
        ]
    },

    {
        name: 'Tijd en middelen',
        categories: [
            /* 15 */
            {
                name: <div><b>De school zorgt er voor dat minstens één persoon uit het team instaat voor de coördinatie en
                    opvolging van het beleid om (cyber)pesten te helpen tegenaan</b>. [PS: dit kan bvb. ook de persoon zijn
                    die instaat voor het introduceren van een herstelgerichte schoolcultuur.]</div>,
                subCategories: [
                    {
                        name: <div>De <b>coördinator</b> van het antipestbeleid beschikt over <b>kwaliteiten</b> (kennis, vaardigheden
                            en attitude) die aansluiten bij de opdracht die hij/zij/die krijgt toevertrouwd.</div>,
                        weight: 1,
                        id: 34
                    },
                    {
                        name: <div>De <b>coördinator</b> van het antipestbeleid krijgt in zijn/haar/hun opdracht <b>voldoende tijd</b> toegemeten voor zijn/haar/hun opdracht.</div>,
                        weight: 1,
                        id: 35
                    },
                    {
                        name: <div>De <b>coördinator</b> wordt <b>systematisch betrokken bij elk overleg</b> en elke beslissing die
                            van betekenis is/kan zijn voor het antipestbeleid van de school. </div>,
                        weight: 1,
                        id: 36
                    },
                ]
            },

            /* 16 */
            {
                name: <div>De school zorgt er voor dat er dagelijks (en binnen de normale werkuren) <b>minstens één
                    iemand beschikbaar is als luisterend oor en hulp voor wie melding doet/wil doen van een
                    (cyber)pestprobleem.</b></div>,
                subCategories: [{name: null, weight: 2, id: 37}]
            },

            /* 17 */
            {
                name: <div>De school garandeert dat er <b>na het melden</b> van een pest- of cyberpestprobleem <b>altijd
                    en op korte termijn (vb. binnen 3 werkdagen) antwoord/reactie volgt</b> op deze melding.</div>,
                subCategories: [{name: null, weight: 2, id: 38}]
            },

            /* 18 */
            {
                name: <div><b>De school voorziet in een gepaste gespreksruimte</b> (veilig, discreet, sfeer van
                    huiselijkheid en vertrouwen): die ruimte laat toe om ook pest- en cyberpestproblemen discreet te
                    bespreken.</div>,
                subCategories: [{name: null, weight: 1, id: 39}]
            },
            /* 19 */
            {
                name: <div>De school <b>investeert</b> (letterlijk) in de <b>permanente vorming van het zorg- en
                    schoolteam m.b.t. dit thema</b>. Men is actief begaan met de deskundigheidsbevordering van het
                    schoolpersoneel en maakt/houdt daar jaarlijks middelen voor vrij.</div>,
                subCategories: [{name: null, weight: 1, id: 40}]
            },
            /* 20 */
            {
                name: <div><b>De school zorgt er voor dat ouders geïnformeerd raken over de verantwoordelijkheid die zij
                    kunnen nemen</b> om er mee voor te zorgen dat pestenproblemen, samen met hen, zo goed en snel
                    mogelijk kunnen worden aangepakt.</div>,
                subCategories: [{name: null, weight: 1, id: 41}]
            }
        ]
    },

    {
        name: 'Professionalisering',
        categories: [
            /* 21*/
            {
                name: <div><b>De school zet strategisch (vb. toepassen PDCA-model), oordeelkundig en volgehouden in op het
                    versterken van het beleid inzake de geestelijke gezondheid en het welbevinden van de leerlingen (en
                    de leerkrachten)</b>.</div>,
                subCategories: [
                    {
                        name: <div>Op basis van de door haar gemaakte evaluaties <b>zet de school aantoonbaar in op
                            verbeteracties</b> of acties om het bestaande beleid op peil te houden of te versterken.
                        </div>,
                        weight: 2,
                        id: 42
                    },
                    {
                        name: <div><b>De school beschikt over sterk geschoolde collega’s en/of laat zich bijstaan door
                            externen</b> (pedagogische adviseurs, trainers, nascholingsorganisaties, anderen) om haar
                            eigen beleid en aanpak vorm te geven en/of te versterken.</div>,
                        weight: 2,
                        id: 43
                    },
                    {
                        name: <div><b>De school neemt op regelmatige basis doelgerichte initiatieven om de kennis,
                            vaardigheden en samenwerkingsmogelijkheden van en tussen alle schoolbetrokkenen
                            (leerkrachten, ouders, leerlingen, …) kracht bij zetten.</b></div>,
                        weight: 1,
                        id: 44
                    },
                    {
                        name: <div><b>Nieuwe medewerkers worden bij aanvang van hun opdracht geïnformeerd over het
                            antipestbeleid/gezondheidsbeleid van de school.</b> Ze vernemen daarbij ook wat er minimaal
                            van hen wordt verwacht en op wie ze kunnen rekenen, mocht zich een (cyber)pestprobleem
                            voordoen.</div>,
                        weight: 1,
                        id: 45
                    },
                ]
            },

            /* 22 */
            {
                name: <div><b>Schoolintern wordt op regelmatige basis tijd gemaakt voor werkmomenten waarbij
                    leerkrachten elkaar bijstaan met advies, inspirerende voorbeelden en ondersteunende feedback (vb.
                    voor het aanpakken van probleemsituaties).</b></div>,
                subCategories: [{name: null, weight: 2, id: 46}]
            },

            /* 23 */
            {
                name: <div><b>De hulpverlener(s) binnen de school (zorgjuf, leerlingenbegeleider, jongerencoach,
                    schoolpsycholoog, …) werken goed samen en houden zich aan de discretieplicht.</b></div>,
                subCategories: [{name: null, weight: 2, id: 47}]
            },
        ]
    },

    {
        name: 'Evaluatie en bijsturing',
        categories: [
            /* 24 */
            {
                name: <div><b>De school peilt op regelmatige basis en via een systematische bevraging het welbevinden van
                    haar leerlingen (en medewerkers)</b>. Hun pestervaringen worden expliciet mee bevraagd.</div>,
                subCategories: [
                    {
                        name: <div>De school peilt regelmatig (minstens om de 2 jaar) naar het <b>welbevinden</b> en de
                            schooltevredenheid van de leerlingen. </div>,
                        weight: 2,
                        id: 48
                    },
                    {
                        name: <div>De school toetst regelmatig in welke mate haar leerlingen te maken hebben met <b>(cyber)pesterijen</b>, gelinkt aan het schoolgebeuren.</div>,
                        weight: 2,
                        id: 49
                    },
                ]
            },

            /* 25 */
            {
                name: <div><b>De school registreert systematisch alle gevallen van pesten en grensoverschrijdend gedrag
                    bij of tussen haar leerlingen.</b></div>,
                subCategories: [{name: null, weight: 1, id: 50}]
            },

            /* 26 */
            {
                name: <div><b>De werkgroep/de persoon die het pestbeleid coördineert evalueert jaarlijks haar werking en
                    rapporteert daarover aan de directie van de school.</b></div>,
                subCategories: [{name: null, weight: 2, id: 51}]
            },

            /* 27 */
            {
                name: <div><b>De school betrekt alle schoolbetrokkenen (personeel, leerlingen, ouders) bij het evalueren
                    van het door haar gevoerde beleid en laat zich bijsturen door hun adviezen en voorstellen.</b>
                </div>,
                subCategories: [{name: null, weight: 2, id: 52}]
            },

            /* 28 */
            {
                name: <div><b>De school kan aantonen dat ze inspeelt op of rekening houdt met de reflecties en
                    aanbevelingen die door de andere schoolbetrokkenen worden aangereikt.</b></div>,
                subCategories: [{name: null, weight: 1, id: 53}]
            },

        ]
    },


]