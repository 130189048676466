import {Header} from "modules/navs/Header";
import {Footer} from "modules/navs/Footer";
import React from "react";

import './BetekenisScores.css'

export const BetekenisScores = () => {


    return (
        <>
            <Header/>
            <main>
                <div className={'container'}>
                    <div className={'betekenis-scores'}>
                        <h1>De evaluatietool ‘Zelfscan Pesten Onderwijs’</h1>
                        <b>Score toekennen aan 7 hoofdindicatoren</b>
                        <p>De evaluatietool hieronder bevraagt de actuele schoolvisie en -praktijk: op welke manier
                            wordt (cyber)pesten op dit ogenblik door de school begrepen en aangepakt? Dat gebeurt door
                            een hele reeks items te bevragen die elk onderdeel (kunnen) zijn van een integrale of
                            school-brede aanpak (zie hoger).</p>
                        <p>Zeven hoofdindicatoren komen aldus in beeld. Elk van hen wordt getoetst op basis van een
                            aantal sub-indicatoren. Het is de bedoeling dat de persoon of personen die de bevraging
                            hanteert/hanteren, elk item gaan scoren. Wie deze evaluatie op z’n eentje wil doen, doet dat
                            op basis van zijn/haar/hun kijk op het geldend beleid van de school. Wordt de
                            evaluatieoefening in groep gemaakt, dan zal de groep elk item eerst moeten bespreken.
                            Vervolgens zal de persoon die de vergadering in handen neemt, op basis van wat gezegd werd,
                            een scorevoorstel doen. Die score wordt voorgelegd aan de groep en de groep stemt in met het
                            voorstel of motiveert waarom een andere score wenselijk is.</p>
                        <p>De digitale variant laat zien of alle sub-vragen werden beantwoord.Dan toont het scherm bvb.
                            een 8/8-score, wat betekent dat alle 8 subvragen werden geëvalueerd. Afhankelijk van het
                            scoreniveau
                            kleurt de hoofdindicator dan rood (onvoldoende uitgewerkt/aanwezig), oranje (aanwezig maar
                            nog heel wat groeimarge) of groen (goed uitgewerkt en aanwezig) in.</p>
                        <p>Door toedoen van het digitaal invullen van de scores vormt zich een web-structuur. Die
                            visualeert hoe sterk of goed het huidige beleid is uitgebouwd. Op basis van wat zich
                            aandient, kunnen nieuwe doelstellingen en acties worden voorgesteld en/of gepland.</p>

                        <b>Legende bij de scores </b>
                        <p>Bij het evalueren primeert de vraag hoe goed en sterk het huidige beleid is uitgebouwd en
                            ingang vindt in de praktijk van elke dag. Dat gebeurt aan de hand van vier
                            scoremogelijkheden. De vier scores trachten weer te geven hoe grondig elk aspect is
                            uitgewerkt/wordt toegepast en hoe tevreden men daarover is.</p>

                        <p><b>Score 4</b> - Wijst op een <b>hoge mate van tevredenheid</b> over dit item. <b>Het item
                            dat bevraagd
                            wordt, realiseert zich ten volle in de eigen school: er is sprake van een geïntegreerd,
                            samenhangend en structureel verankerd beleid</b> dat alle betrokkenen bereikt en mee neemt.
                            Voor
                            wie dit niveau van realisatie bereikt heeft, komt het er vooral op aan de goede werking
                            gaande te houden.</p>

                        <p><b>Score 3</b> – Deze score drukt uit dat men (gewoon) <b>tevreden</b> is over de manier
                            waarop dit item op
                            dit moment vorm krijgt: het beleid is in ontwikkeling, krijgt vorm en verankert zich stap
                            voor stap. De school is <b>op weg naar een geïntegreerd, consistent en goed uitwerkt
                                beleid</b>. Er
                            is zeker nog werk aan winkel. Het komt er op aan het actuele beleid verder te verfijnen, te
                            verdiepen of nog krachtiger aanwezig te stellen. Hiervoor zullen er bijkomende initiatieven
                            moeten worden genomen.</p>

                        <p><b>Score 2</b> - Deze score maakt duidelijk dat school bezig is dit item vorm te geven maar er
                            is <b>nog
                                veel werk aan te winkel</b>. Wat er tot dusver gerealiseerd werd, staat onvoldoende ver
                            en
                            vraagt om verdere uitwerking en/of om koerscorrecties. <b>Er wordt nog te mager, te
                                fragmentair, en/of met onvoldoende overtuiging en kracht ingezet op dit item</b>. Van
                            een
                            structurele aanpak is nog geen sprake.</p>

                        <p><b>Score 1</b> - Deze score wijst er op dat <b>dit item amper of niet werd aangepakt of
                            gerealiseerd</b>.
                            Op dit vlak scoort de school momenteel nog erg zwak. <b>Het ontbreekt aan beleid</b>.</p>

                        <p><b>Score ‘n.v.t.’</b> – Deze score laat toe aan te duiden dat de persoon die de
                            enquête invult niets of te weinig afweet over het item dat bevraagd wordt. Om een of andere
                            reden moet de betrokkene het <b>antwoord schuldig</b> blijven.</p>


                    </div>
                </div>
            </main>
            <Footer/>
        </>
    );
}