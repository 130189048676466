import React, {useEffect, useState} from 'react';
import './Scale.css'

type Props = {
    value: any,
    disabled: boolean,
    onChange: Function
}

const Scale = ({value = undefined, disabled, onChange}: Props) => {


    const [selected, setSelected] = useState<any>(value)
    const options = [
        {label: 'n.v.t.', value: null},
        {label: '1', value: 0.25},
        {label: '2', value: 0.5},
        {label: '3', value: 0.75},
        {label: '4', value: 1}
    ]

    useEffect(() => {
        if (selected !== undefined) {
            onChange(selected)
        }
    }, [selected])

    return (
        <div className={`scale ${disabled ? 'disabled' : ''}`}>
            {options.map((option, index) =>
                <div key={index}
                     onClick={() => !disabled && setSelected(option.value)}
                     className={`option ${selected === option.value ? 'active' : ''}`}>
                    {option.label}
                </div>)}
        </div>
    );
}

export default Scale;