import React from 'react';

import SubCategory from "components/SubCategory";
import {Category as CategoryClass} from "lib/category";
import {SubCategory as SubCategoryClass} from "lib/sub-category";


import './Category.css'
import {useData} from "hooks/use-data";

type Props = {
    index: number
    category: CategoryClass
}

const Category = ({category, index}: Props) => {

    const {updateData, indicatorCalculator, incompleteSubcategories} = useData()

    const hasIncompleteSubcategories = category.subCategories.some(subCategory => incompleteSubcategories.includes(subCategory.id))

    return (
        <div className={`category ${hasIncompleteSubcategories ? 'error' : ''}`}>
            <h3 className={'category-name'}>
                <div className={'speech-bubble'}>{index}</div><div className={'content'}>{category.name}</div>
            </h3>
            {
                category.subCategories.map((subCategory: SubCategoryClass, i) =>
                    <SubCategory key={i} categoryIndex={index} index={i + 1} subCategory={subCategory}/>)
            }
        </div>
    );
}

export default Category;