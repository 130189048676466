import {Header} from "modules/navs/Header";
import {Footer} from "modules/navs/Footer";
import React from "react";

import antipestslang from 'assets/img/antipestslang.png'
import './Situering.css'

export const Situering = () => {

    return (
        <>
            <Header/>
            <main>
                <div className={'container'}>
                    <div className={'situering'}>
                        <div className={'panel'}>
                            <b>Als het brandt, moet je blussen!</b>

                            <p>Omwille van de brandveiligheid is elke school verplicht een hele boel maatregelen te
                                treffen.
                                Dat is begrijpelijk en staat niet ter discussie. Als samenleving willen we vermijden dat
                                zich een catastrofe of ongeval zou voordoen in een school of internaat.</p>

                            <p>Scholen moeten daarom beschikken over een goed opgeleide preventieadviseur. Deze persoon
                                moet er over waken dat alle andere voorzorgsmaatregelen volledig en correct worden
                                uitgevoerd. De
                                schoolinfrastructuur moet worden voorzien van brandtrappen, nooduitgangen, (jaarlijks te
                                testen) blusapparatuur, noodverlichting, richtingwijzers en procedures die, in geval van
                                een
                                incident, strikt en snel dienen te worden opgevolgd. Brandoefeningen houden iedereen
                                alert en laten
                                toe de procedures te testen. Essentiële routines worden op die manier ingeoefend.</p>

                            <p>Om voor de hand liggende redenen stelt niemand zich vragen bij de investeringen in tijd,
                                geld en mensenkracht om te doen wat nodig is. Want elk mensenleven telt en elk
                                schoolgebouw dient
                                gevrijwaard. We mogen blij zijn dat de goed toegepaste voorzorgsmaatregelen er toe
                                geleid
                                hebben dat zich sinds ruime tijd quasi geen catastrofes meer hebben voorgedaan.
                                Voorkomen werkt!</p>

                            <p>Het zou van gezond verstand getuigen mocht Vlaanderen met pesten en cyberpesten
                                eenzelfde
                                logica hanteren. Vergeten we niet dat pesten en cyberpesten elk jaar wel de nodige
                                slachtoffers
                                maakt: ruim 1/3 van de 10 tot 13-jarigen krijgt te maken met (cyber)pesterijen. 1 op 5
                                van de 10-
                                tot 18-jarigen ontsnapt er niet aan. Pesterijen zorgen voor veel averij. Het kan
                                kinderen en
                                jongeren tot wanhoop drijven. Pesttrauma’s zinderen levenslang na. Ook de directe
                                omgeving
                                (thuis, klasgroep, club, …) deelt mee in de schade. De Zweedse organisatie Friends
                                berekende
                                dat de lange termijn kostprijs van één jaar pesterijen op school overeenkomt met het
                                jaarsalaris
                                van 35 800 Zweedse leerkrachten! Inzetten op preventie is dus meer dan lonend! Het
                                draagt ook bij tot wat we onszelf en iedereen toewensen: een stevige dosis menselijk
                                geluk.</p>
                        </div>

                        <h1>Inleiding</h1>

                        <p>Het mag verrassen maar Vlaanderen koos er tot dusver niet voor om scholen ondubbelzinnig te
                            verplichten tot het opstellen en hanteren van een schooleigen antipestbeleid. In landen als
                            Nederland, het Verenigd Koninkrijk, Denemarken, Noorwegen, Zweden en Finland is dit wel het
                            geval.</p>

                        <p>Wél stellen we vast dat steeds meer Vlaamse scholen de keuze maken om een antipest- of
                            pestpreventiebeleid te installeren. Deze scholen zijn zich bewust van hun
                            verantwoordelijkheid. Voor hen is zonder meer duidelijk dat leren/studeren en zorgen voor
                            welbevinden onlosmakelijk met elkaar verbonden zijn. Ze hebben begrepen dat pesten/cyberpesten
                            het leervermogen van leerlingen, zowel individueel als collectief, naar beneden haalt.
                            Pesten heeft een kwalijk effect op de betrokken leerlingen, op klasgroepen, op de
                            leerkrachten, op gezinnen en niet in het minst op de hele samenleving. De vele korte en
                            lange termijn effecten van pesten dwingen tot actie.</p>

                        <p>Scholen dragen een belangrijke verantwoordelijkheid bij het voorkomen, opmerken en helpen
                            terugdringen van pestsituaties. Toch is het niet de bedoeling dat enkel leerkrachten aan zet
                            komen. Pesten voorkomen en aanpakken is een gedeelde verantwoordelijkheid. Een goede
                            samenwerking leidt tot een beter en sneller resultaat. Als leerlingen, ouders, het
                            jeugdwerk, sportclubs, het openbaar vervoer, steden en gemeenten, sociale mediaplatvormen,
                            de klassieke media, bedrijven, … elk hun steentje bijdragen, vergroot de impact en lukt het
                            beter om de pestcijfers te drukken. Die gebundelde aanpak zorgt ook voor helderheid.
                            Kinderen en jongeren krijgen dat nog duidelijker de boodschap: pesten kan niet, nooit en
                            nergens. Door de handen in elkaar te slaan, kunnen we pesten stoppen!</p>

                        <h1>Situering van de evaluatietool</h1>

                        <b>Doel</b>

                        <p>Deze evaluatietool stelt scholen in staat om hun bestaande praktijk om (cyber)pesten het
                            hoofd te bieden te evalueren. Dit instrument biedt elke school de mogelijkheid haar huidige
                            praktijk tegen het licht te houden. Zo krijgt de school een duidelijke kijk op wat de school
                            al onderneemt, wat nog ontbreekt en/of waar er een tandje kan worden bijgestoken.</p>

                        <p>De evaluatietool is geen afvinklijst maar een oriënterend instrument. De items die de tool in
                            beeld brengt, duiken veelvuldig op in scholen die succesvol zijn. Ze illustreren wat een
                            school kan doen om haar pestbeleid kracht bij te zetten. De kracht van het beleid vloeit
                            voort uit de visie van de school, het onderbouwd en samenhangend karakter van de
                            initiatieven die genomen worden en de motivatie en drive van het team. De koppeling tussen
                            het pedagogisch DNA van de school en haar antipestbeleid moet vanzelfsprekend zijn.</p>

                        <b>Focus</b>

                        <p>Wetenschappelijk onderzoek toont aan dat een schoolbrede aanpak (‘whole school approach’) de
                            beste garanties biedt voor het succesvol terugdringen/voorkomen van pestgedrag.</p>

                        <p>Deze aanpak combineert een veelheid aan initiatieven die er, alles bij elkaar genomen, voor
                            zorgen dat het welbevinden op school toeneemt en de kans op (cyber)pesterijen afneemt.
                            (Cyber)pesterijen komen er op initiatief van wie wil pesten maar dat neemt niet weg dat ze
                            het resultaat zijn van een veelheid aan factoren. Richtte men de blik aanvankelijk vooral op
                            de centrale spilfiguren (de pestende persoon en de persoon die werd gepest) dan wordt er nu
                            veel breder gekeken. Pesterijen gedijen bijzonder goed in een toxische omgeving. Dan wordt
                            het school- en omgangsklimaat gekenmerkt door kilte, onverschilligheid, na-ijver,
                            onveiligheid en wantrouwen. Het aantal (ook stiekeme) conflicten loopt hoog op. Wie anders
                            is of minder goed scoort, hoort er niet bij. De oplopende spanning zorgt voor stress, angst
                            en eenzaamheid. Pesterijen zijn in zo’n omgeving niet uit de lucht.</p>

                        <p>Als pesten opduikt, dan is het omdat de beschermende factoren moeten onderdoen voor de
                            risicofactoren. Anders gezegd: kinderen of jongeren gaan over tot pestgedrag als de
                            omstandigheden dat toelaten. In een conflictueuze groep, met een minder betrokken leerkracht
                            of opvoeder, en waar elke boodschap over pesten afwezig is gebleven, grijpen sommigen
                            sneller naar het pestwapen om op die manier aan invloed te winnen of om te voorkomen zelf
                            opnieuw te worden gepest. Kortom, in een toxische klas- of schoolomgeving worden negatieve
                            processen versterkt en duiken er meer conflicten, pesterijen, leer- en omgangsproblemen op.
                            In een positieve groep gebeurt het omgekeerde: daar trekken zich door toedoen van de
                            leerkracht(en) én van goed gestemde populaire leerlingen positieve processen op gang en
                            lopen veel minder leerlingen de kans om te worden gepest, gediscrimineerd of
                            uitgesloten.</p>

                        <p>De ‘whole school approach’ staat garant voor een breed vertakt, meersporig en - tegelijk -
                            samenhangend beleid. Het valt onderzoekers op dat scholen die het op dit vlak goed doen,
                            prat gaan op hun pedagogisch project. Het kenmerkende school-DNA is terug te vinden in de
                            vele structurele maar even goed occasionele initiatieven die door de school worden genomen.
                            In deze scholen is het antipestbeleid geen schaamlapje, noch een oppervlakkige vernislaag.
                            Integendeel, het is een wezenlijk onderdeel van het pedagogisch beleid van de school.
                            Leerlingen en ouders zijn hierover geïnformeerd en weten wat hen te doen staat. Leerkrachten
                            krijgen er spreekwoordelijk een kompas en een wandelroute in handen. Dat zorgt er voor dat
                            ze, elk op hun niveau, hun verantwoordelijkheid sneller en op een meer effectieve manier
                            opnemen. (Cyber)pesterijen worden vlugger bespreekbaar gemaakt en dat laat toe om ze ook
                            sneller in de kiem te smoren.</p>

                        <b>Pesten en cyberpesten: één geheel</b>

                        <p>Deze tool beschouwt pesten en cyberpesten als één geheel. Het online pesten mag dan
                            bijzondere kenmerken hebben toch zien we dat het ook hier om gedragingen gaat die aansluiten
                            bij klassiek pesten: uitsluiten, roddelen, valse berichten verspreiden, kwetsende
                            boodschappen versturen, bedreigen, discrimineren, … .</p>

                        <p>Bij cyberpesten of online pesten is het machtsonevenwicht tussen wie pest en wie slachtoffer
                            is, het grootst. Wellicht verklaart dit waarom cyberpesten zo hard kan binnenkomen. Bij
                            ‘internetpesten’ neemt het controleverlies grotere proporties aan: slachtoffers (en hun
                            omgeving) weten vaak nog minder wat gedaan. Cyberpesterijen kunnen zich bovendien op elk
                            moment voordoen: de grens tussen schooltijd en thuistijd/vrije tijd valt weg. Dag en nacht,
                            zeven dagen op zeven, kan het cyberpesten toeslaan. Die aanhoudende dreiging is bijzonder
                            uitputtend en eist haar tol.</p>

                        <p>Leerkrachten en ouders hebben er alle belang bij om de speciale band tussen jongeren en de
                            sociale media goed te vatten. Een open, niet-veroordelende maar nieuwsgierige houding speelt
                            in ieders voordeel: als zich problemen voordoen vallen kinderen en jongeren liefst terug op
                            wie hen nauw aan het hart ligt. Ouders en grootouders, goeie vrienden, leerkrachten of
                            opvoeders die in hun ogen betrokken en betrouwbaar zijn. Jongeren vertrouwen zich bij
                            voorkeur toe aan volwassenen die hen niet meteen met de vinger wijzen en die open staan voor
                            hun leefwereld.</p>

                        <p>Scholen weten hiermee wat hen te doen staat: verduidelijk de eigen visie en stel je, als
                            school, betrokken en geëngageerd op. Kies voor heldere krijtlijnen. Zorg dat iedereen weet
                            wat hem/haar/die te doen staat en houd de drempel om problemen te melden zo laag mogelijk.
                            Nabije, veilige, betrouwbare en discrete meldpunten doen het veel beter.</p>

                        <b>Antipestslang wijst de weg</b>

                        <p>De integrale of schoolbrede aanpak kenmerkt zich, zoals hogerop reeds werd gezegd, door het
                            combineren van diverse initiatieven. Die zorgen er samen voor dat pestgedrag achterwege
                            blijft, minder steun krijgt of sneller wordt opgegeven.</p>

                        <p>De door de VLOR ontwikkelde antipestslang schetst waar scholen best werk van maken. Ruw
                            geschetst gaat het om een combinatie van 5 grote doelen (lees: actiedomeinen), m.n.</p>

                        <ol>
                            <li>De zorg voor een warm en verbindend schoolklimaat/welbevinden op school</li>
                            <li>Het ontplooien van doelgerichte preventieve maatregelen
                                <ul>
                                    <li>Met beschuttend of beschermend karakter (‘protectieve factoren’ versterken)</li>
                                    <li>Ontradende en probleemvoorkomende maatregelen (‘risicofactoren’ tot een minimum
                                        herleiden)
                                    </li>
                                </ul>
                            </li>
                            <li>Het installeren en werkzaam houden van een laagdrempelig, nabij en effectief
                                ‘meldsysteem’ (om te zorgen dat beginnende of bestaande problemen snel in beeld komen)
                            </li>
                            <li>Het gepast inzetten van degelijke, effectieve en beproefde interventiemethodieken om er
                                voor te zorgen dat het pesten stopt. Daarnaast is het de bedoeling dat de veiligheid,
                                het vertrouwen in elkaar en de verbondenheid tussen alle betrokken partijen terug wordt
                                hersteld.
                            </li>
                            <li>Het tijdig in kaart brengen van mogelijke partners/samenwerkingsverbanden om de meest
                                lastige problemen en de eventuele nazorg te kunnen toevertrouwen aan
                                hooggespecialiseerde mensen of diensten (buiten de school).
                            </li>
                        </ol>


                        <img src={antipestslang} className={'antipestslang'}/>

                    </div>
                </div>
            </main>
            <Footer/>
        </>
    );
}