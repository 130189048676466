import React from 'react';

import Category from "components/Category";
import {Indicator as IndicatorClass} from "lib/indicator";
import {Category as CategoryClass} from "lib/category";

import './Indicator.css'

type Props = {
    indicator: IndicatorClass
    indexOffset: number
}


const Indicator = ({indicator, indexOffset}: Props) => {

    return (
        <div className="indicator">
            <div className={'indicator-image-container'}>
                <h2 className={'indicator-name'}>
                    {indicator.name}
                </h2>
            </div>
            {
                indicator.categories.map((category: CategoryClass, index) => <Category key={index}
                                                                                       index={indexOffset + index + 1}
                                                                                       category={category}/>)
            }
        </div>

    );
}

export default Indicator;