import React from 'react';
import {
    Chart as ChartJS,
    RadialLinearScale,
    PointElement,
    LineElement,
    Filler,
    Tooltip,
    Legend,
} from 'chart.js';

import {Radar} from 'react-chartjs-2';

import './RadarChart.css'
import {useData} from "hooks/use-data";

ChartJS.register(
    RadialLinearScale,
    PointElement,
    LineElement,
    Filler,
    Tooltip,
    Legend
);


export const RadarChart = () => {

    const {chartData} = useData()

    const data = {
        labels: chartData.map((indicatorData: any) => indicatorData.label),
        datasets: [
            {
                label: 'Score',
                data: chartData.map((indicatorData: any) => indicatorData.value),
                backgroundColor: 'rgba(0, 52, 105, 0.4)',
                borderColor: 'rgba(0, 52, 105, 1)',
                borderWidth: 1,
            },
        ],
    };

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
            r: {
                min: 0,
                max: 10,
                stepSize: 1,
                pointLabels: {
                    font: {
                        size: 16
                    },
                    color: (context: any, options: any) => {
                        const index = context.index;
                        const data = chartData[index]
                        if (!data.completed)
                            return '#AAA'
                        if (data.value < 5)
                            return '#f00'
                        else if (data.value < 7)
                            return 'orange'
                        else
                            return 'green'
                    },
                }
            }
        },
        plugins: {
            legend: {
                display: false
            }
        },
        layout: {
            padding: {left: 0, right: 0}
        }
    }

    return (
        <div className={'radar-container'}>
            <Radar data={data} options={options}/>
        </div>
    )
}