import {useData} from "hooks/use-data";
import {Header} from "modules/navs/Header";
import {Indicator as IndicatorClass} from "lib/indicator";
import Indicator from "components/Indicator";
import {Footer} from "modules/navs/Footer";
import React, {useState} from "react";
import {Navigate} from "react-router-dom";
import {RadarChart} from "components/RadarChart";
import Lottie from "lottie-react";
import loadingAnimation from "assets/animations/loading.json";

import './Survey.css'
import arrow from 'assets/img/arrow-right-solid.svg'
import {calculateCompetitionQualification} from "utils/calculate-competition-qualification";
import {submitSurvey} from "utils/submit-survey";



export const Survey = () => {

    const {
        indicatorCalculator,
        indicatorSizes,
        userEmail,
        setIncompleteSubcategories,
        submitted,
        setSubmitted
    } = useData()

    const [loading, setLoading] = useState(false)
    const [nominated, setNominated] = useState<any>(false)

    if (!userEmail) {
        return <Navigate to={'/signup'}/>
    }


    const submit = () => {

        const nrOfQuestions = 53
        let incompleteSubcategories = []

        for (let i = 1; i <= nrOfQuestions; i++) {
            if (!indicatorCalculator.values.has(i)) {
                incompleteSubcategories.push(i)
            }
        }
        setIncompleteSubcategories(incompleteSubcategories)
        if (incompleteSubcategories.length === 0) {
            window.scrollTo(0, 0);
            setLoading(true)
            const qualifiesForCompetition = calculateCompetitionQualification(indicatorCalculator)

            submitSurvey(userEmail, qualifiesForCompetition, indicatorCalculator, setNominated)
                .then(response => response.json())
                .then(result => {
                    setSubmitted(true)
                    localStorage.removeItem('data')
                })
                .catch(error => console.log('Error:', error))
                .finally(() => setLoading(false));


        } else {
            document.getElementById(`subcategory-${incompleteSubcategories[0]}`)?.scrollIntoView({behavior: 'smooth'});
        }
    }

    return (
        <>
            {
                loading &&
                <div className={'loading'}>
                    <Lottie animationData={loadingAnimation} className={'loading-section-animation'}/>
                </div>
            }

            {
                !loading && nominated &&
                <div className={'nominated-backdrop'}>
                    <div className={'nominated'}>
                        <h2>
                            Gefeliciteerd, jullie school is genomineerd!
                        </h2>
                        <p>Als gevolg van jullie hoge score, komt jullie school in aanmerking om deel te nemen aan onze
                            wedstrijd. Dit is een geweldige erkenning voor de inspanningen die jullie hebben
                            geleverd om pesten op school aan te pakken.</p>
                        <p>Om deel te nemen aan de wedstrijd, hebben we nog wat meer informatie nodig over jullie school en
                            het specifieke anti-pestbeleid. Dit helpt ons om een vollediger beeld te krijgen van jullie
                            aanpak en om de wedstrijd zo eerlijk en informatief mogelijk te maken.</p>

                        <b>Je kan nu of later deelnemen; je ontvangt hierover nog een aparte email.</b>
                        <div className={'buttons'}>
                            <button className={'secondary'} onClick={() => setNominated(false)}>Misschien Later</button>
                            <button onClick={() => {
                                setNominated(false)
                                window.open(nominated, '__blank')
                            }}>
                                Neem Deel
                            </button>
                        </div>
                    </div>
                </div>
            }
            <Header/>
            <main>
                <div className={'container'}>
                    {
                        submitted &&
                        <div id={'result-chart'} className={'indicator'}>
                            <h2>Resultaat</h2>
                            <RadarChart/>
                        </div>

                    }
                    {
                        indicatorCalculator.indicators.map((indicator: IndicatorClass, index: number) => {
                            const previousIndicatorCategories = indicatorSizes.slice(0, index).reduce((sum: number, current: number) => sum + current, 0);
                            return <Indicator key={index} indicator={indicator}
                                              indexOffset={previousIndicatorCategories}/>
                        })
                    }
                    {
                        !submitted &&
                        <button className={'submit'} onClick={submit}>
                            Bereken Score <img src={arrow}/>
                        </button>
                    }
                </div>
            </main>
            <Footer/>
        </>
    );
}