import {IndicatorJSON} from "types";
import {Indicator} from "lib/indicator";

export class IndicatorCalculator {
    public indicators: Indicator[]
    public values: Map<any, any>


    constructor(indicators: IndicatorJSON[], options: any = {}) {
        this.indicators = indicators.map(indicatorJSON => new Indicator(indicatorJSON, this))
        // const subCategoryIds = this.indicators.flatMap(indicator => indicator.categories.flatMap(category => category.subCategories.map(subCategory => subCategory.id)));
        this.values = new Map(options?.values)
    }

    calculateWeights() {
        return this.indicators.map(indicator => {
                const {totalWeight, weightedValues} = indicator.calculateWeights();
                return {
                    label: indicator.label || indicator.name,
                    totalWeight: totalWeight,
                    weightedValues: weightedValues,
                }
            }
        )
    }

    getChartData() {
        const indicatorData = this.calculateWeights()

        return indicatorData.map(indicator => {
            const value = indicator.totalWeight ? (indicator.weightedValues / indicator.totalWeight * 10).toFixed(1) : 0
            return {
                label: indicator.label,
                value: value,// indicator.getWeightedValue(),
                completed: 10// indicator.isCompletelyFilledIn()
            }
        })
    }

}