import {Header} from "modules/navs/Header";
import {Footer} from "modules/navs/Footer";
import React, {useRef, useState} from "react";

import './Signup.css'
import {useData} from "hooks/use-data";
import {useNavigate} from "react-router-dom";

export const Signup = () => {

    const {userEmail, setUserEmail} = useData()
    const [email, setEmail] = useState(userEmail || '')
    const inputRef = useRef<any>(null);
    const navigate = useNavigate()


    const submit = () => {
        if (inputRef?.current?.reportValidity()) {
            setUserEmail(email)
            localStorage.setItem('email', email)
            navigate('/')
        }
    }

    return (
        <>
            <Header/>
            <main>
                <div className={'container'}>
                    <div className={'signup'}>
                        <p>Welkom! We zijn verheugd u te mogen introduceren aan onze tool voor het evalueren van het pestbeleid op scholen.</p>
                        <p>Voordat u begint, vragen we u om uw e-mailadres in te voeren. Dit wordt enkel gebruikt om
                            u de resultaten van de evaluatie te sturen. Uw gegevens worden vertrouwelijk
                            behandeld.</p>
                        <div className={'form-element'}>
                            <label>E-mail</label>
                            <input ref={inputRef}
                                   required={true}
                                   type={'email'}
                                   value={email}
                                   placeholder={'jouw@email.be'}
                                   onChange={(e) => setEmail(e.target.value)}/>
                        </div>
                        <button onClick={submit}>Start Zelfscan</button>
                    </div>
                </div>
            </main>
            <Footer/>
        </>
    );
}