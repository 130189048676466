import {IndicatorCalculator} from "lib/indicator-calculator";

export const calculateCompetitionQualification = (indicatorCalculator: IndicatorCalculator) => {
    const calculateWeights = indicatorCalculator.calculateWeights()
    // Op elke hoofdindicator scoort de school ten minste  60%
    const indicatorScores = calculateWeights.map(weights => weights.weightedValues / weights.totalWeight)
    console.info('indicatorScores', indicatorScores)
    if (indicatorScores.some(indicatorValue => indicatorValue < 0.60))
        return false


    const totals = calculateWeights.reduce((acc, item) => ({
            totalWeightedValues: acc.totalWeightedValues + item.weightedValues,
            totalWeight: acc.totalWeight + item.totalWeight
        }),
        {totalWeightedValues: 0, totalWeight: 0}
    );
    const globalScore = totals.totalWeightedValues / totals.totalWeight;
    console.info('globalScore', globalScore)
    // de school behaalt een eindscore (in totaal) van minstens 70%
    if (globalScore < 0.70)
        return false

    // Over de hele vragenlijst telt de school maximum vijf 25%- en/of blanco scores (= n.v.t./weet niet).
    const nullValues = Array.from(indicatorCalculator.values.values()).filter(value => [null, 0.25].includes(value))
    console.log('lowScores', nullValues)
    return nullValues.length <= 5;


}