import React from 'react';

import './Footer.css'


export const Footer = () => {
    return (
        <footer>
            <div className="footer-accent">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440.18 92">
                    <line x1="0.09" y1="89.93" x2="1440.09" y2="2.07"
                          style={{fill: 'none', stroke: '#32b67a', strokeWidth: '2px'}}/>
                </svg>
            </div>
            <div className="footer-credentials text-end">
                <div className="container">
                    <div>
                        Webdesign door <a href={'https://digifox.be'} target={'_blank'}>DIGIFOX</a>
                        <div>© 2023 | Alles over pesten. Alle rechten voorbehouden.</div>
                    </div>
                </div>
            </div>
        </footer>
    )
}