import React, {useEffect, useState} from "react";
import {IndicatorCalculator} from "lib/indicator-calculator";
import {INDICATORS} from "data/indicators";

const ChartDataContext = React.createContext<any>(null)

let jsonData: any = null
try {
    jsonData = JSON.parse(atob(localStorage.getItem('data') || ''))
} catch (e) {

}

try {
    const searchParams = new URLSearchParams(window.location.search);
    if (searchParams.get('s')) {
        jsonData = JSON.parse(atob(searchParams.get('s') || ''))
    }
} catch (e) {

}

const indicatorCalculator = new IndicatorCalculator(INDICATORS, {values: jsonData?.values})
const indicatorSizes = indicatorCalculator.indicators.map(indicator => indicator.categories.length)


export const ChartDataProvider = ({children}: any) => {
    const [chartData, setChartData] = useState(indicatorCalculator.getChartData())
    const [userEmail, setUserEmail] = useState(jsonData?.email)
    const [incompleteSubcategories, setIncompleteSubcategories] = useState<any>([])
    const [submitted, setSubmitted] = useState(jsonData?.submitted)
    const [nominated, setNominated] = useState(jsonData?.nominated)
    const scanUrl = jsonData?.code
    //const [code, setCode] = useState()


    const updateData = () => {
        setChartData(indicatorCalculator.getChartData())

        const persistData = {
            email: userEmail,
            values: Array.from(indicatorCalculator.values.entries())
        }
        const base64Code = btoa(JSON.stringify(persistData))
        localStorage.setItem('data', base64Code)
    }

    return (
        <ChartDataContext.Provider value={{
            userEmail, setUserEmail,
            indicatorCalculator,
            indicatorSizes,
            updateData,
            chartData, setChartData,
            submitted, setSubmitted,
            nominated, setNominated,
            scanUrl,
            incompleteSubcategories, setIncompleteSubcategories
        }}>
            {children}
        </ChartDataContext.Provider>
    )
}

export const useData = () => React.useContext(ChartDataContext)