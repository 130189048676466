import React from 'react';
import {SubCategory as SubCategoryClass} from "lib/sub-category";

import './SubCategory.css'
import Scale from "components/Scale";
import {useData} from "hooks/use-data";


type Props = {
    subCategory: SubCategoryClass
    categoryIndex: number
    index: number
}

const SubCategory = ({subCategory, categoryIndex, index}: Props) => {

    const {updateData, indicatorCalculator, incompleteSubcategories, setIncompleteSubcategories, submitted} = useData()

    return (
        <div className={`sub-category ${incompleteSubcategories.includes(subCategory.id) ? 'error' : ''}`}
             id={`subcategory-${subCategory.id}`}>
            {subCategory.name &&
                <div className={'sub-category-name'}>
                    <div className={'speech-bubble'}>{categoryIndex}.{index}</div>
                    {subCategory.name}
                </div>
            }
            {
                subCategory.weight &&
                <Scale value={indicatorCalculator.values.get(subCategory.id)}
                       disabled={!!submitted}
                       onChange={(value: any) => {
                           subCategory.setValue(value)
                           updateData()
                           setIncompleteSubcategories(incompleteSubcategories.filter((id: number) => id !== subCategory.id))
                       }}/>
            }
        </div>
    );
}

export default SubCategory;