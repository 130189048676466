import React from 'react';

import logo from 'assets/img/logo-white.svg'

import './Header.css'
import {NavLink} from "react-router-dom";
import {useData} from "hooks/use-data";

import signout from 'assets/img/signout.svg'

export const Header = ({showButtons = true}) => {

    const {userEmail} = useData()

    const signOut = () => {
        localStorage.setItem('data', '')
        window.location.reload()
    }

    return (
        <>
            <div className={'header'}>
                <div className={'container'}>
                    <div className={'top-bar'}>
                        <a href={'https://allesoverpesten.be/'}>
                            <img src={logo} className={'logo'}/>
                        </a>
                        <div className={'right-column'}>
                            <h1>ZELFSCAN PESTEN ONDERWIJS</h1>
                            {userEmail &&
                                <div className={'email'}>{userEmail} <img src={signout} onClick={signOut}/></div>
                            }
                        </div>
                    </div>

                    {showButtons &&
                        <div className={'nav'}>
                            <NavLink to={'/situering'} target={'_blank'} className={'nav-item'}>
                                Situering
                            </NavLink>
                            <NavLink to={'/achtergrond-informatie'} target={'_blank'} className={'nav-item second'}>
                                Achtergrond Informatie
                            </NavLink>
                            <NavLink to={'/betekenis-scores'} target={'_blank'} className={'nav-item third'}>
                                Betekenis Scores
                            </NavLink>
                        </div>
                    }
                </div>
                <div className="accent-triangle-bottom">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1240 75.01" className="triangle-bottom">
                        <polygon points="1240 75.01 1240 0 0 75.01 1240 75.01"/>
                    </svg>
                </div>

            </div>
        </>
    )
}