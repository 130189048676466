import {Header} from "modules/navs/Header";

import {Footer} from "modules/navs/Footer";
import React, {useEffect, useState} from "react";
import {Navigate} from "react-router-dom";
import Lottie from "lottie-react";
import loadingAnimation from "assets/animations/loading.json";

import './Competition.css'
import {useData} from "hooks/use-data";

const indicators = ['Beleidsvisie', 'Participatie en gedragenheid', 'Communicatie', 'Samenwerking', 'Tijd en middelen', 'Professionalisering', 'Evaluatie en bijsturing']

export const Competition = () => {


    const {nominated, scanUrl} = useData()
    const [loading, setLoading] = useState(false)
    const [status, setStatus] = useState<any>(null)
    const searchParams = new URLSearchParams(window.location.search);
    const base64Code = searchParams.get('s')


    if (!nominated || !base64Code) {
        return <Navigate to={"/"}/>;
    }

    const submitForm = async (e: any) => {
        setLoading(true)
        e.preventDefault()
        const formData = new FormData(e.target);

        formData.set('_wpcf7_unit_tag', 'wpcf7-f13-p25-o1')
        formData.set('zelfscan', `https://zelfscanpestenonderwijs.be/?s=${base64Code}`)

        try {
            fetch(`https://api.zelfscanpestenonderwijs.be/wp-json/contact-form-7/v1/contact-forms/13/feedback`, {
                method: 'POST',
                body: formData
            }).then(response => response.json())
                .then(data => setStatus(data.status))
                .catch((e) => setStatus('error'))
                .finally(() => setLoading(false))
        } catch (e) {
            setStatus('error')
        }
    }

    return (
        <>
            {
                loading &&
                <div className={'loading'}>
                    <Lottie animationData={loadingAnimation} className={'loading-section-animation'}/>
                </div>
            }
            <Header showButtons={false}/>
            <main>
                <div className={'container'}>
                    <h2>Inschrijving Wedstrijd</h2>
                    <div className={'competition-information'}>
                        <p>Hallo,</p>
                        <p>Fijn dat jullie school deelneemt aan de pesten-dat-kan-niet-wedstrijd!</p>
                        <p>Meedoen betekent dat jullie nog wat extra informatie moeten toevoegen aan jullie
                            zelfscanscores.
                            Dat laat de jury toe zich een preciezer beeld te vormen van antipest- of
                            pestpreventiewerking op
                            school.</p>
                        <p>Het invullen van het wedstrijddossier gebeurt bij voorkeur door iemand die/door een groepje
                            dat
                            goed op de hoogte is van het antipestbeleid van jullie school. De zorgcoördinator, de
                            pedagogisch
                            directeur, de voortrekker van een werkgroep, … kunnen het dossier wellicht probleemloos
                            invullen.
                            Het toevoegen van teksten, videomateriaal, foto’s, schermafdrukken, folders, … biedt de jury
                            de kans
                            zich een nog preciezer beeld te vormen van wat jullie ondernemen.</p>
                        <p><b>Welke info of gegevens houden jullie best achter de hand?</b></p>
                        <ul>
                            <li>Visietekst antipestbeleid</li>
                            <li>Verslagen werkgroep(en), incl. evaluatie bestaande werking</li>
                            <li>Jaaractieplan/Actieplan</li>
                            <li>Websitelinks (jullie antipestplan; info voor leerlingen, info voor ouders)</li>
                            <li>Schermafdruk van of link naar jullie digitale meldknop – foto’s van meldpunt(en) op
                                school
                            </li>
                            <li>Speelplaatsplan; foto’s speelplaats en spel- of activiteitenaanbod tijdens de
                                middagpauze
                            </li>
                            <li>Situering sensibilisering binnen het curriculum/de leerplannen</li>
                            <li>Resultaat leerlingenbevraging</li>
                            <li>Overzicht nascholingen</li>
                            <li>Affiches, lesmaterialen, …</li>
                            <li>Foto’s en/of verslagen van jullie peer-to-peerwerking bij de leerlingen (vb; conflixers,
                                buddywerking, peters en meters, …).
                            </li>
                        </ul>

                        <p>Dank voor het invullen van het dossier.</p>
                        <p>Hopelijk zorgt dit er voor dat jullie school lid wordt van de selecte club van winnaars van
                            de pesten-
                            dat-kan-niet-prijs! Veel succes toegewenst.</p>

                        <p>Vriendelijke groet</p>
                        <p>Gie Deboutte, voorzitter van de wedstrijdjury</p>
                    </div>
                    <form onSubmit={submitForm} className={'competition'}>
                        <h3>Gegevens School</h3>
                        <label htmlFor="naam-school">Naam School</label>
                        <input type="text" id="naam-school" name="naam-school" required/>

                        <label htmlFor="adres-school">Adres School</label>
                        <input type="text" id="adres-school" name="adres-school" required/>

                        <label htmlFor="tel-school">Telefoon School (zonder spaties of leestekens)</label>
                        <input type="tel" pattern="\+?[\d]{5,40}" id="tel-school" name="tel-school" required/>

                        <label htmlFor="onderwijsaanbod">Onderwijsaanbod</label>
                        <input type="text" id="onderwijsaanbod" name="onderwijsaanbod" required/>

                        <label htmlFor="aantal-leerlingen">Aantal Leerlingen (hele school)</label>
                        <input type="text" id="aantal-leerlingen" name="aantal-leerlingen" required/>

                        <label htmlFor="aantal-leerkrachten">Aantal Leerkrachten (hele school)</label>
                        <input type="text" id="aantal-leerkrachten" name="aantal-leerkrachten" required/>

                        <label htmlFor="website-school">Website School</label>
                        <input type="text" id="website-school" name="website-school" required/>

                        <label htmlFor="email-school">Email School</label>
                        <input type="email" id="email-school" name="email-school" required/>

                        <h3>Gegevens Contactpersoon</h3>
                        <label htmlFor="naam-contactpersoon">Naam Contactpersoon</label>
                        <input type="text" id="naam-contactpersoon"
                               name="naam-contactpersoon" required/>

                        <label htmlFor="email-contactpersoon">Email
                            Contactpersoon</label>
                        <input type="email" id="email-contactpersoon"
                               name="email-contactpersoon" required/>

                        <label htmlFor="tel-contactpersoon">Tel
                            Contactpersoon (zonder spaties of leestekens)</label>
                        <input type="tel" pattern="\+?[\d]{5,40}" id="tel-contactpersoon"
                               name="tel-contactpersoon" required/>


                        <h3>Projectgegevens</h3>
                        <label htmlFor="opstartdatum-project">Wanneer werd het project waarop dit dossier betrekking
                            heeft opgestart?</label>
                        <input type="date" id="opstartdatum-project"
                               name="opstartdatum-project" required/>

                        <label htmlFor="initiatiefnemers">Wie waren/zijn de eerste initiatiefnemers?</label>
                        <input type="text" id="initiatiefnemers"
                               name="initiatiefnemers" required/>

                        <label htmlFor="aanleiding">Wat was de aanleiding voor de opstart van jullie
                            antipestwerking?</label>
                        <input type="text" id="aanleiding"
                               name="aanleiding" required/>

                        <label htmlFor="realisaties">Wat zijn de belangrijkste en meest geslaagde realisaties (max. 5)
                            van jullie project?</label>
                        <textarea id="realisaties" name="realisaties" required/>

                        <label htmlFor="uitdagingen">Welke uitdagingen (max. 3) zien jullie nog liggen? Waar moeten
                            jullie verder nog werk van maken? </label>
                        <textarea id="uitdagingen" name="uitdagingen" required/>

                        <h3>Motivatie Score</h3>

                        {
                            indicators.map((indicatorName: string, i) =>
                                <div className={'indicator-motivation'} key={i}>
                                    <label>{indicatorName}</label>
                                    <textarea name={`motivatie-${indicatorName.toLowerCase().replace(/\s+/g, '-')}`}
                                              placeholder={'Motiveer en/of voeg bijlagen toe'}/>
                                    <input type={"file"}
                                           accept={"application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"}
                                           name={`bijlage-${indicatorName.toLowerCase().replace(/\s+/g, '-')}`}/>
                                </div>
                            )
                        }

                        <input type="submit" value="Verstuur" disabled={status === 'mail_sent'}/>
                    </form>

                    {
                        status && status !== 'mail_sent' &&
                        <div className={'error-panel'}>
                            Het verzenden is mislukt. Gelieve alle velden te controleren. Bijlagen mogen max 5MB zijn.
                            <br/>Bij aanhoudende problemen, contacteer de <a href={"mailto:info@digifox.be"}>webmaster</a>
                            <br/>Error code: {status}
                        </div>
                    }

                    {
                        status === "mail_sent" &&
                        <div className={'success-panel'}>
                            Je deelname is verzonden!
                        </div>
                    }
                </div>
            </main>
            <Footer/>
        </>
    );
}