import React from 'react';


import { Routes, Route } from 'react-router-dom';
import {Signup} from "pages/Signup";
import {Survey} from "pages/Survey";
import {Result} from "pages/Result";
import {Situering} from "pages/Situering";
import {AchtergrondInformatie} from "pages/AchtergrondInformatie";
import {BetekenisScores} from "pages/BetekenisScores";
import {Competition} from "pages/Competition";

function App() {
    return (
        <Routes>
            <Route path="/signup" element={<Signup/>}/>
            <Route path="/" element={<Survey/>}/>
            <Route path="/result" element={<Result/>}/>
            <Route path="/wedstrijd" element={<Competition/>}/>
            <Route path="/situering" element={<Situering/>}/>
            <Route path="/achtergrond-informatie" element={<AchtergrondInformatie/>}/>
            <Route path="/betekenis-scores" element={<BetekenisScores/>}/>
        </Routes>
    );
}

export default App;
