import {Header} from "modules/navs/Header";
import {Footer} from "modules/navs/Footer";
import React from "react";

import './AchtergrondInformatie.css'
import verandermodel from 'assets/img/verandermodel.png'

export const AchtergrondInformatie = () => {


    return (
        <>
            <Header/>
            <main>
                <div className={'container'}>
                    <div className={'achtergrond-informatie'}>
                        <h1>Achtergrondinformatie bij de evaluatietool</h1>

                        <b>Oorsprong</b>
                        <p>De Zelfscan Pesten Onderwijs bouwt voort op wetenschappelijk onderzoek.</p>
                        <p>Dit evaluatie-instrument werd in eerste instantie ontworpen voor hogescholen en
                            universiteiten . Het initiële model brengt 7 hoofdindicatoren in beeld die elk gericht zijn
                            op een bepaald onderdeel van het schoolbeleid. De uitwerking van elk beleidsonderdeel en het
                            samenspel tussen dit zevental is bepalend voor de kwaliteit van wat zich in de school
                            realiseert.</p>
                        <p>Alle hoofdindicatoren staan op gelijke voet. Ze worden met andere woorden als even belangrijk
                            beschouwd. Samen staan ze garant voor een beleid dat doet wat moet: er voor zorgen dat
                            pesten en cyberpesten ten gronde wordt aangepakt. Dat lukt beter als het beleid nauw
                            verweven is met of gevoed wordt door de visie en pedagogische aanpak van de school én kan
                            rekenen op de steun van de directie en van de andere actoren binnen de
                            schoolgemeenschap.</p>
                        <p>Zoals de tool duidelijk maakt, schuilt er achter elke hoofdindicator een reeks
                            sub-indicatoren. Zij expliciteren meer in detail waarmee scholen het verschil kunnen maken.
                            Zo wordt bijvoorbeeld onder de noemer ‘beleidsvisie’ (= hoofdindicator) gewezen op het
                            belang van een uitgeschreven visietekst (= sub-indicator) waarin de school bijvoorbeeld
                            uitlegt hoe ze (cyber)pesten opvat en wat ze van leerkrachten, leerlingen en ouders verwacht
                            (= sub-indicator).</p>
                        <p> De 7 hoofdindicatoren die in beeld komen, zijn:</p>
                        <ol>
                            <li>Beleidsvisie</li>
                            <li>Participatie en gedragenheid</li>
                            <li>Communicatie</li>
                            <li>Samenwerking</li>
                            <li>Tijd en middelen</li>
                            <li>Professionele vorming</li>
                            <li>Evaluatie en bijsturing</li>
                        </ol>

                        <b>Gebruiksaanwijzing</b>
                        <p>Omdat alle hoofdindicatoren even belangrijk zijn, krijgen ze in de evaluatietool eenzelfde
                            ‘gewicht’ toegemeten, m.n. de maximumscore van 10 punten. Die score wordt bereikt als alle
                            sub-items of sub-indicatoren, die schuilen achter de hoofdindicator, een maximumscore
                            toegewezen krijgen. Het aantal sub-indicatoren per hoofditem varieert. Inhoudelijk komen er
                            telkens andere elementen in beeld, de ene al belangrijker dan de andere. Zij hebben elk hun
                            eigen ‘gewicht’.</p>

                        <p>Het is aan de gebruikers van het evaluatie-instrument om een score toe te kennen aan elk
                            onderdeel dat belicht wordt. De scores die gegeven worden aan de sub-indicatoren worden per
                            hoofdindicator samengeteld en monden uit in een totaalscore voor die hoofdindicator. Die
                            score komt in de digitale versie van deze tool onmiddellijk zichtbaar in beeld en zorgt er
                            voor dat de naam van de hoofdindicator groen (= positieve score), oranje (= matige score)
                            dan wel rood (= onvoldoende of te magere score) inkleurt.</p>

                        <p>Aan het eind van de oefening visualiseert zich een 7-hoekig vlak op de webstructuur van het
                            score-instrument: dit vlak geeft weer hoe sterk of hoe goed het actuele beleid is uitgebouwd
                            en waar verbeterinitiatieven mogelijk of noodzakelijk zijn. Na uitvoering van die
                            initiatieven kan de evaluatie-oefening worden herhaald. Zo groeien scholen jaar na jaar naar
                            het door hen gewenste niveau van uitbouw van hun antipestbeleid.</p>

                        <b>Door wie te gebruiken?</b>
                        <p>De tool is in eerste instantie bedoeld voor wie beleidsverantwoordelijkheid draagt, t.t.z. de
                            pedagogische directie, de cel leerlingenbegeleiding, de zorgcoördinator, de
                            werkgroep(verantwoordelijke(n)), … . Toch moedigen we elke school aan om de
                            evaluatie-oefening samen met leerkrachten, leerlingen en ouders te maken. Dat laatste vraagt
                            natuurlijk meer vergadertijd maar het heeft als groot voordeel dat er vanuit meer dan één
                            perspectief wordt gekeken en dat het draagvlak voor het gevoerde beleid consequent wordt
                            versterkt. Het is de bedoeling om tijdens de bespreking tot een gezamenlijke score per item
                            te komen. Het is aan de gesprekleider om, inspelend de bespreking, per item een gemotiveerd
                            scorevoorstel te doen (= ‘consensusscore’). Krijgt dit voorstel de nodig steun, dan wordt
                            die score ingevoerd in het digitale toetsingsinstrument.</p>

                        <b>Van bespreking naar quotering en actie</b>
                        <p>De bespreking en het gevisualiseerde eindresultaat of ‘scoreweb’ brengen de sterktes, zwaktes
                            en de groeimogelijkheden van het actuele schoolbeleid in beeld.</p>
                        <p>Hiermee krijgen scholen die informatie in handen die hen toelaat nieuwe actiepunten te
                            bepalen. Het ene actiepunt zal wellicht dwingender zijn dan het andere. Er zullen doelen bij
                            zijn die snel gerealiseerd kunnen worden, terwijl andere meer tijd en grotere inspanningen
                            zullen vragen. Het komt er op aan een realistische planning op te stellen, afgestemd op de
                            beschikbare middelen, mensenkracht en tijd.</p>

                        <h1>Hoe verder na de toetsing? Van evaluatie naar actie.</h1>
                        <p>Het veranderingsproces dat gepaard gaat met het ontwikkelen en in voege brengen van een
                            duurzaam antipest- of pestpreventiebeleid plaatst een school voor vele uitdagingen. Wie
                            hierbij extra houvast kan gebruiken, vindt mogelijk inspiratie in het verandermodel van
                            Kotter (1995, 2012). </p>

                        <img src={verandermodel} className={'verandermodel'}/>
                        <small>Verandermodel van Kotter (Kotter, J. (1996) Leading Change)</small>

                        <p>Om de kans op succes zo groot mogelijk te houden, reikt Kotter een veranderroute aan met
                            daarin 8 stappen of fasen. Elke fase haakt in op de vorige. Samen leiden ze naar het
                            verwachte einddoel: de realisatie van een nieuwe ontwikkeling die zich verankert in de
                            werking van een organisatie (lees: school). </p>

                        <b>Fase 1: Vertrek vanuit de wil tot verandering</b>
                        <p>Een verandering vindt plaats op voorwaarde dat mensen echt naar iets nieuws, iets anders
                            willen toewerken. De wil, noodzaak of goesting tot verandering is de basis van het
                            veranderingsgebeuren. Mensen moeten naar iets nieuws willen toewerken. Voor Kotter vertrekt
                            alles vanuit een urgentiebesef. Het komt er dus op aan emoties als hoop, vertrouwen,
                            optimisme en enthousiasme los te weken. Het visualiseren of concretiseren van het te maken
                            verschil werkt beter dan een lijvig en goed becijferd rapport. Door de veranderingsdoelen
                            te koppelen aan wat mensen zelf belangrijk vinden, werkt men de juiste motivatie in de
                            hand. </p>

                        <b>Fase 2: Vorm een leidende coalitie</b>
                        <p>Top down veranderingsprocessen zorgen voor veel weerstand. Daarom maak je als school best
                            werk van een ‘leidende coalitie’ waarin het schoolteam of – breder nog – de hele
                            schoolgemeenschap zich herkent. Kotter raadt schooldirecties aan om zich te omringen met een
                            enthousiaste groep mensen. Bij voorkeur gaat het om personen die zich constructief maar ook
                            kritisch durven opstellen. Liever geen schoothondjes maar wel mensen die gegrond weerwerk
                            durven bieden. </p>

                        <p>Vanzelfsprekend besteden de leidinggevenden het veranderingsproces niet volledig uit aan de
                            veranderingscoalitie. Het is immers van cruciaal belang dat ook de directie zich
                            geëngageerd, d.w.z. activerend en enthousiasmerend opstelt. Ze treedt dus meer op de
                            voorgrond, volgt alle ontwikkelingen op de voet, maakt middelen vrij, trekt de juiste mensen
                            aan en zet in op nascholingen die aansluiten bij het projectplan. </p>

                        <b>Fase 3: Ontwikkel visie en strategie</b>
                        <p>Formuleer een heldere, concrete, realistische en haalbare visie die uitdrukt wat jullie als
                            school willen bereiken. Ga na welke veranderingen noodzakelijk en/of wenselijk zijn. Wat
                            zijn de knelpunten? Wat willen jullie toevoegen? Wat is nodig om het nog beter te doen? </p>

                        <p>Bekijk vervolgens welke elementen uit jullie visie (waarden, streefdoelen) brandstof kunnen
                            geven aan wat er doen staat. Wat kan er daarnaast nog motiverend werken? </p>

                        <p>Neem de tijd om aan alle betrokkenen, en zeker aan de personen die uitvoering moeten geven
                            aan de taken die er aan komen, uit te leggen welke werkpunten/opdrachten in handen moeten
                            worden genomen en waarom. Verduidelijk welk verschil er gemaakt moet worden en welke
                            meerwaarde dit zal opleveren, voor henzelf en voor andere betrokkenen. Maak een goed
                            werkplan op, volgens de SMART-principes en baken af wie wat zal doen. Stel een timing voorop
                            en houdt jullie aan de planning. Verduidelijk ten slotte op welke manier en binnen welke
                            termijn er een eerste evaluatie zal volgen. </p>

                        <b>Fase 4: Communiceer en creëer zekerheid: verduidelijk het doel en het de weg er naartoe.</b>

                        <p>Elk veranderingsproces roept weerstand op. Beluister goed welke vragen of noden er achter die
                            weerstand schuilen. Mensen houden er niet van het oude vertrouwde achter zich te laten voor
                            iets wat zich nog niet heeft getoond of gerealiseerd. </p>

                        <p>Om onzekerheid, angst en wantrouwen zo beperkt mogelijk te houden, komt het er op aan zo
                            transparant mogelijk en eerlijk te communiceren. Maak duidelijk wat er te gebeuren staat.
                            Mensen verdragen geen spoken. Ze willen helderheid en voorspelbaarheid. Voor zover die
                            gegeven kan worden. Door zelfkritisch te zijn en inspraak te vragen, stellen de directie en
                            de veranderingscoalitie zich kwetsbaar maar toegankelijk op. Die openheid is nodig om tot
                            een echte dialoog te komen met alle betrokkenen. Zo krijgt ieder het gevoel gehoord te
                            worden en te kunnen bijdragen tot het project dat voorligt. </p>

                        <b>Fase 5: Creëer de juiste randvoorwaarden</b>

                        <p>De belangrijkste to do’s voor deze fase zijn: (a) het invoeren van prioritaire initiatieven,
                            (b) het verwijderen van obstakels en (c) het evalueren en bijsturen van het aan gang zijnde
                            proces.</p>

                        <p>Kotter wijst op het belang van juiste keuzes: geef best voorrang aan wat zich als urgent
                            aandient. Zijn er geen urgente noden, kies dan voor wat relevant en haalbaar is. Door op
                            korte termijn een beperkt aantal – ook symbolische – stappen te zetten, wordt duidelijk dat
                            het niet bij woorden blijft. Het maakt ook duidelijk dat de inspanningen die gevraagd wordt,
                            wel degelijk tot iets dienen.</p>

                        <p>Daarnaast komt het er op aan alle obstakels te detecteren. Wat werkt storend of belemmerend?
                            Waarom willen sommige mensen niet mee? Wat werkt demotiverend? Wat voedt twijfel en
                            onzekerheid? Door rekening te houden met wat leeft, toont men zich betrokken en komt men de
                            obstakels die doorwegen sneller op het spoor.</p>

                        <p>Op basis van deze vaststellingen kan men beslissen om bepaalde bijsturingen te doen of extra
                            ondersteuning te voorzien voor wie dat nodig heeft.</p>

                        <p>Een goede communicatie en afstemming tussen de leidinggevenden, de veranderingscoalitie en de
                            planuitvoerders is hoe dan ook vereist. Medewerkers verdienen steun en waardering voor de
                            inspanningen die ze leveren. Door op regelmatige tijdstippen inspraakrondes en evaluaties in
                            te bouwen, wordt brandstof binnengehaald die het veranderingsproces op dreef houdt.</p>

                        <b>Fase 6: Creëer korte termijn successen</b>

                        <p>Het klonk hogerop al door: het is lonend om in te zetten op ‘quick wins’. Korte termijn
                            realisaties werken immers motiverend. Medewerkers ervaren deze ‘quick wins’ als een beloning
                            voor hun harde werk.</p>

                        <p>Zet van zodra het kan zowel de mensen als hun realisaties in de verf en vier de vooruitgang
                            die wordt geboekt. Het ‘meten’ van de geboekte vooruitgang kan de geboekte ‘winst’ concreter
                            helpen maken.</p>

                        <p>Dit ‘meten’ kan op vele manieren gebeuren, via observaties, getuigenissen (kwalitatieve
                            methodes) of via vragenlijsten of registraties (kwantitatieve methodes).</p>

                        <b>Fase 7: Bewaak en bewaar de verandering</b>

                        <p>De zevende fase van het veranderingsproces is gericht op het verbreden en verdiepen van het
                            aan gang zijnde proces. Nu de eerste realisaties zichtbaar worden, bestaat het risico dat
                            men overmoedig wordt en iets te snel het gevoel krijgt dat de rit is gereden.</p>

                        <p>Deze fase is een tussenfase: de oude oever heeft men verlaten en de nieuwe oever, aan de
                            overkant, komt in beeld. Bekijk daarom wat werkt, versterk, verbreed en verdiep wat ingang
                            vindt. Dat kan o.m. door (nog) meer mensen te betrekken (via kwaliteitscirkels,
                            nascholingen, inspraakmomenten of het delegeren van taken), coaching aan te bieden,
                            rustmomenten in te bouwen, de taakverdeling goed te bewaken en er voor te zorgen dat niemand
                            opbrandt.</p>

                        <p>Het verankeren en verbinden van wat nieuw verworven is met het reeds bestaande, is een
                            opdracht voor de leidinggevenden en de veranderingscoalitie. Zo kan ervoor gekozen worden om
                            bepaalde deeltaken in handen te leggen van de werkgroep ‘Sfeer en animatie’, de werkgroep
                            ‘Speelplaatsbeleid’ of de werkgroep ‘Ouderbetrokkenheid’.</p>

                        <b>Fase 8: Borg de verandering</b>
                        <p>Een veranderingsproces rondt zich succesvol af wanneer men er in slaagt de verandering te
                            integreren en vast te houden. Dat lukt maar als het DNA van de school terug te vinden is in
                            de nieuwe of vernieuwde visie en praktijk.</p>
                        <p>De laatste uitdaging bestaat er in om al wat nieuw is, levend en dynamisch te houden. Dat kan
                            door middel van nieuwe, bijkomende initiatieven die in lijn liggen met wat inmiddels vorm
                            heeft gekregen.</p>
                        <p>Via metingen, enquêtes, formele en informele feedbackgesprekken, verbeterwerkgroepen of
                            andere methodieken kunnen alle betrokkenen blijven bijdragen tot het in stand houden en
                            voltrekken van de nieuwe omgangscultuur en praktijk. Aangezien de populatie van leerlingen
                            en ouders aanhoudend wijzigt, speelt het schoolteam een cruciale rol bij het duurzaam
                            verankeren of borgen van de verandering die beoogd wordt. Het is essentieel dat zowel de
                            directie als de andere leden van het schoolteam doordrongen blijven van hun opdracht en
                            missie.</p>


                    </div>
                </div>
            </main>
            <Footer/>
        </>
    );
}