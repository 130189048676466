import {IndicatorCalculator} from "lib/indicator-calculator";

export const submitSurvey = async (userEmail :string, isNominated :boolean, indicatorCalculator : IndicatorCalculator, setNominated: any) => {

    const nominated = isNominated.toString()
    const submittedCode = {
        email: userEmail,
        values: Array.from(indicatorCalculator.values.entries()),
        submitted: new Date().toISOString(),
        nominated: nominated
    }
    const base64Code = btoa(JSON.stringify(submittedCode))

    const formData = new FormData()
    formData.set('_wpcf7_unit_tag', 'wpcf7-f13-p25-o1')
    formData.set('email',  userEmail)
    formData.set('nominated',  nominated)
    formData.set('code', `https://zelfscanpestenonderwijs.be/?s=${base64Code}`)

    if(isNominated){
        const competitionFormData = new FormData()
        const competitionUrl = `https://zelfscanpestenonderwijs.be/wedstrijd?s=${base64Code}`
        competitionFormData.set('_wpcf7_unit_tag', 'wpcf7-f13-p25-o1')
        competitionFormData.set('email',  userEmail)
        competitionFormData.set('code', competitionUrl)
        setNominated(competitionUrl)
        await fetch(`https://api.zelfscanpestenonderwijs.be/wp-json/contact-form-7/v1/contact-forms/21/feedback`, {
            method: 'POST',
            body: competitionFormData
        })
    }

    return fetch(`https://api.zelfscanpestenonderwijs.be/wp-json/contact-form-7/v1/contact-forms/11/feedback`, {
        method: 'POST',
        body: formData
    })



}