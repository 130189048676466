import {CategoryJSON, SubCategoryJSON} from "types";
import {SubCategory} from "lib/sub-category";
import {IndicatorCalculator} from "lib/indicator-calculator";


export class Category {
    public name: string
    public subCategories: SubCategory[]
    public tooltip ?: any

    constructor(category: CategoryJSON, calculator: IndicatorCalculator) {
        this.name = category.name
        //this.weight = category.subCategories.reduce((sum: number, subCategory: SubCategoryJSON) => sum + subCategory.weight, 0)
        this.subCategories = category.subCategories.map(subCategoryJSON => new SubCategory(subCategoryJSON, calculator))
        this.tooltip = category.tooltip
    }

    calculateWeights(){
       return this.subCategories.reduce((acc:any, subCategory) => {
            const weightedValue = subCategory.getWeightedValue();
            if (weightedValue !== null) {
                acc.totalWeight += subCategory.weight;
                acc.weightedValues += weightedValue;
            }
            return acc;
        }, {totalWeight: 0, weightedValues:0})
    }

    
    // getWeightedValue() {
    //     return this.subCategories.reduce((sum:number, subCategory) => sum + (subCategory.getWeightedValue() || 0), 0)
    // }

    isCompletelyFilledIn(){
        return this.subCategories.map(subCategories => subCategories.value).every(value => value !== undefined)
    }

}