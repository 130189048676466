import {SubCategoryJSON} from "types";
import {IndicatorCalculator} from "lib/indicator-calculator";

export class SubCategory {
    public id: number;
    public name: string;
    public weight: number;
    public value: number | null | undefined = undefined;
    public tooltip ?: any;
    private calculator: IndicatorCalculator


    constructor(subCategoryJSON: SubCategoryJSON, calculator: IndicatorCalculator) {
        this.id = subCategoryJSON.id
        this.name = subCategoryJSON.name
        this.weight = subCategoryJSON.weight
        this.tooltip = subCategoryJSON.tooltip
        this.calculator = calculator
    }


    setValue(value: number) {
        this.value = value
        this.calculator.values.set(this.id, this.value)
    }

    getWeightedValue() {
        if (this.value) {
            return this.weight * this.value
        }
        return null
    }
}