import {Category} from "lib/category";
import {IndicatorJSON} from "types";
import {IndicatorCalculator} from "lib/indicator-calculator";

export class Indicator {
    public name: string
    // public weight: number
    public categories: Category[]
    public label: any = ''

    constructor(indicator: IndicatorJSON, calculator: IndicatorCalculator) {
        this.name = indicator.name
        this.categories = indicator.categories.map(categoryJSON => new Category(categoryJSON, calculator))
        // this.weight = this.categories.reduce((sum: number, category: Category) => sum + category.weight, 0)
        this.label = indicator.label || this.label
    }

    calculateWeights() {
        return this.categories.reduce((acc: any, category) => {
            const {totalWeight, weightedValues} = category.calculateWeights();
            acc.totalWeight += totalWeight
            acc.weightedValues += weightedValues;
            return acc;
        }, {totalWeight: 0, weightedValues: 0})
    }

    // getWeightedValue(){
    //     const weightedValues = this.categories.reduce((sum:number, category) => sum + category.getWeightedValue(), 0)
    //     return (weightedValues / this.weight) * 10
    // }

    isCompletelyFilledIn(){
        return this.categories.map(categories => categories.isCompletelyFilledIn()).every(boolean => boolean)
    }

}