import {useData} from "hooks/use-data";
import {Header} from "modules/navs/Header";
import {Footer} from "modules/navs/Footer";
import React from "react";

import {RadarChart} from "components/RadarChart";

export const Result = () => {

    const {indicatorCalculator, indicatorSizes, userEmail} = useData()

    return (
        <>
            <Header/>
            <main>
                <div className={'container'}>
                    <RadarChart/>
                </div>
            </main>
            <Footer/>
        </>
    );
}